import React, { useState } from 'react';
import PropTypes from 'prop-types';
import Lightbox from 'react-image-lightbox';
import { Button, Icon } from '@popmenu/common-ui';

import { MinimizeAlt } from '@popmenu/web-icons';
import { createEvent } from '~/utils/eventable';
import { useRestaurant } from '../../../utils/withRestaurant';
import { BASE_Z_INDEX } from '../../../utils/withStyles';

const PhotoGallery = ({ alt, ButtonProps, className, eventableId, eventableType, icon, photos, ...props }) => {
  const restaurantId = useRestaurant().id;
  const [showGallery, setShowGallery] = useState(false);
  const [photoIndex, setPhotoIndex] = useState(0);
  const photo = photos[photoIndex];
  let prevIndex;
  let nextIndex;
  if (photos.length > 1) {
    prevIndex = photoIndex <= 0 ? photos.length - 1 : photoIndex - 1;
    nextIndex = photoIndex >= photos.length - 1 ? 0 : photoIndex + 1;
  }
  return (
    <React.Fragment>
      <Button
        aria-label={props['aria-label']}
        size="large"
        variant="text"
        {...ButtonProps}
        className={className}
        startIcon={<Icon icon={icon} />}
        onClick={() => {
          setShowGallery(true);
          if (eventableId && eventableType) {
            createEvent({
              eventableId,
              eventableType,
              eventType: 'gallery_open_event',
              restaurantId,
            });
          }
        }}
      />
      {showGallery && photo && (
        <Lightbox
          closeLabel="Close Gallery"
          imageTitle={photo.alt || alt}
          mainSrc={photo.photoUrl}
          mainSrcThumbnail={photo.thumbnailUrl}
          nextSrc={typeof nextIndex === 'number' ? photos[nextIndex].photoUrl : null}
          nextSrcThumbnail={typeof nextIndex === 'number' ? photos[nextIndex].thumbnailUrl : null}
          onCloseRequest={() => setShowGallery(false)}
          onMoveNextRequest={typeof nextIndex === 'number' ? () => setPhotoIndex(nextIndex) : null}
          onMovePrevRequest={typeof prevIndex === 'number' ? () => setPhotoIndex(prevIndex) : null}
          prevSrc={typeof prevIndex === 'number' ? photos[prevIndex].photoUrl : null}
          prevSrcThumbnail={typeof prevIndex === 'number' ? photos[prevIndex].thumbnailUrl : null}
          reactModalStyle={{
            overlay: {
              zIndex: BASE_Z_INDEX + 1600,
            },
          }}
        />
      )}
    </React.Fragment>
  );
};

PhotoGallery.defaultProps = {
  alt: null,
  'aria-label': undefined,
  ButtonProps: {},
  className: null,
  eventableId: null,
  eventableType: null,
  icon: MinimizeAlt,
};

PhotoGallery.propTypes = {
  alt: PropTypes.string,
  'aria-label': PropTypes.string,
  ButtonProps: PropTypes.object,
  className: PropTypes.string,
  eventableId: PropTypes.number,
  eventableType: PropTypes.string,
  icon: PropTypes.oneOfType([PropTypes.func, PropTypes.object, PropTypes.string]),
  photos: PropTypes.arrayOf(PropTypes.shape({
    alt: PropTypes.string,
    photoUrl: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  })).isRequired,
  restaurantId: PropTypes.number.isRequired,
};

export default PhotoGallery;
